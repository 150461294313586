import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { SeoQuery } from '../../graphqlTypes'

interface Props {
  description?: string
  lang?: string
  meta?: any[]
  title?: string | null | undefined
  noindex?: boolean
}

const Seo: FunctionComponent<Props> = ({ description, lang = 'en', meta = [], title = '', noindex = false }: Props) => {
  const { site } = useStaticQuery<SeoQuery>(
    graphql`
      query Seo {
        site {
          siteMetadata {
            title
            description
            author {
              name
              email
              twitter
              github
              rss
            }
          }
        }
      }
    `
  )

  if (noindex) meta.push({ name: 'robots', content: 'noindex' })

  const metaDescription = (description !== undefined) ? description : site?.siteMetadata?.description as string

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title as string}
      titleTemplate={`%s | ${site?.siteMetadata?.title as string}`}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title as string
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: site?.siteMetadata?.author?.name as string
        },
        {
          name: 'twitter:title',
          content: title as string
        },
        {
          name: 'twitter:description',
          content: metaDescription
        }
      ].concat(meta)}
    />
  )
}

export default Seo
